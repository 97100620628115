import React from "react";
import { useEffect } from "react";
import FooterUpper from "./FooterUpper";
// import AddBookingIcon from "./AddBookingIcon";
// import NotificationIcon from "./NotificationIcon";
import { Link, useLocation } from "react-router-dom";
import "./About.css";
const About = () => {
  const location = useLocation();

  // Scroll to the top of the page whenever the location changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      {/* <AddBookingIcon />
      <NotificationIcon /> */}

      <div className="pg_contact_us">
        <div className="banner relative">
          <img src="./assets/img/about-us.webp" alt="about us img"></img>
          <div className="banner_content">
            <div className="bc_inner">
              <div className="container">
                <h3 className="banner_subheading">
                  Our Commitment to Excellence{" "}
                </h3>
                <h2 className="banner_heading">
                  Navigating Success Together: Our Collective Journey{" "}
                </h2>
                {/* <Link to="contactus"> */}
                {/* <button className="btn_fill"> Us</button> */}
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about_data_parent_div">
        <div className="container">
          <div className="row">
            <div className="row-parent">
              <div className="about_img_div">
                <img
                  className="denta_about_img"
                  src="./assets/img/22-logo.webp"
                  alt="logo dentamax"
                />
              </div>
              <div className="top_right_div">
                <h2 id="top-heading">
                  Dentamax – The Power of Advanced Medicines & Healthcare
                </h2>
                <br />
                <h6 className="subheading">
                  <b
                    style={{
                      fontSize: "20px",
                      color: "var(--black-color)",
                    }}
                  >
                    Specialized Healthcare Excellence Since 2002
                  </b>
                </h6>
                <p id="top-para" style={{ color: "var(--black-color)" }}>
                  At Dentamax, we take pride in our legacy of providing
                  exceptional healthcare services since 2002. With decades of
                  serving you with quality healthcare, our commitment to
                  specialized care has made us a trusted name in the industry.
                  We prioritize your well-being and strive to deliver
                  outstanding treatment and results with our range of
                  specialized services to you. At Dentamax, we are driven by our
                  commitment to excellence in specialized healthcare. Our team
                  of thorough professionals utilize the latest medical
                  technologies and practices to ensure you receive top-notch
                  treatment and achieve the best possible medication and
                  results.
                </p>
              </div>
            </div>
            <div className="bottom_text">
              <p style={{ color: "var(--black-color)" }}>
                <b>Your Wellness is Our Priority</b> <br /> Your health and
                satisfaction are at the forefront of everything we do. At
                Dentamax, we emphasize a patient-centric approach, ensuring that
                you receive personalized care and attention throughout your
                healthcare journey.
              </p>
              <p style={{ color: "var(--black-color)" }}>
                <br />
                **Terms and conditions apply.
                <br />
                <br />
                Exclusive Offers Only at Dentamax Medical Center, Al Nahda
                Street, Al Qusais 2, Dubai: Complimentary Dental Checkup Teeth
                Cleaning and Polishing for only 100 AED 30% Discount on Dental
                Procedures 30% Discount on Homeopathy & Hijama Services Offers
                valid till 31th January 2024 Only.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <FooterUpper /> */}
    </>
  );
};

export default About;
