import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useAuthContext } from "./hooks/useAuthContext";
import { useEffect, useState } from "react";
import { useDocument } from "./hooks/useDocument";
import { useCollection } from "./hooks/useCollection";
import { Link } from "react-router-dom";
// components
import Navbar from "./components/Navbar";
import More from "./components/More";
import About from "./components/About";
import AllCustomerBookings from "./components/AllCustomersBookings";
import ContactUs from "./components/ContactUs";
import HomeHeroBanners from "./components/HomeHeroBanners";
import Team from "./components/Team";
import Disclaimer from "./components/Disclaimer";
import Services from "./components/Services";
import SetupSlots from "./components/SetupSlots";
import PGLoginPhone from "./pages/login/PGLoginPhone";
import NavbarBottom from "./components/NavbarBottom";
import Footer from "./components/Footer";
import InternationalPatients from "./components/InternationalPatients";
import AddBookingIcon from "./components/AddBookingIcon";
import CallUsIcon from "./components/CallUsIcon";
import Gallery from "./components/Gallery";
import PGCosmetology from "./components/PGCosmetology";
import Insurances from "./components/Insurances";
// admin

import PGAdminDashboard from "./pages/roles/admin/PGAdminDashboard";
import PGAdminUser from "./pages/roles/admin/PGAdminUser";
// frontdesk
import PGFrontdeskDashboard from "./pages/roles/fontdesk/PGFrontdeskDashboard";
// customer
import Faq from "./pages/faq/Faq";
import PrivacyPolicy from "./pages/privacy policy/PrivacyPolicy";
import Products from "./pages/products/Products";
import PGCustomerPayments from "./pages/roles/customer/PGCustomerPayments ";

// import PGLogin from "./pages/login/PGLogin";
import PGSignup from "./pages/login/PGSignup";
import PGProfile from "./pages/profile/PGProfile";
import AddBill from "./pages/create/AddBill";
import AddPhoto from "./pages/create/AddPhoto";
import AddDocument from "./pages/create/AddDocument";
import PGCalendarBooking from "./pages/pg calender booking/PGCalendarBooking";
import Notification from "./pages/notification/Notification";
// styles
import "./App.css";
import AdminSettings from "./pages/roles/admin/AdminSettings";
import AddBooking from "./pages/create/AddBooking";
import PGHome from "./pages/roles/customer/PGHome";
import PGDermaAds from "./pages/dermatology ads/PGDermaAds";
import PGDentalAds from "./pages/dental ads/PGDentalAds";
import PGCustomerDashboard from "./pages/roles/customer/PGCustomerDashboard";
import ProductDetails from "./pages/product details/ProductDetails";
import ServiceDetails from "./pages/services details/ServiceDetails";
import DoctorDetails from "./pages/doctor details/DoctorDetails";
import FooterUpper from "./components/FooterUpper";
import NotificationIcon from "./components/NotificationIcon";
import ThankYou from "./pages/thank you page/ThankYou";

function App() {
  const { authIsReady, user } = useAuthContext();
  const [currentModeStatus, setCurrentModeStatus] = useState("dark");

  const { document: dbTextContentDocuments, error: dbTextContentError } =
    useDocument("settings", "PWA");

  const { document: dbDisplayModeDocuments, error: dbDisplayModeError } =
    useDocument("settings", "mode");

  const { documents: dbBookingsDocuments, error: dbBookingsError } =
    useCollection("bookings", ["status", "in", ["upcoming", "approved"]]);

  const { document: appointmentPermission } = useDocument(
    "settings",
    "appointment-permission"
  );

  // Filter bookings to include only the ones created by the logged-in user
  const userBookings =
    dbBookingsDocuments && user
      ? dbBookingsDocuments.filter((booking) => booking.createdBy === user.uid)
      : [];

  // Find the latest upcoming booking from the user's bookings
  const latestUserBooking =
    dbBookingsDocuments && dbBookingsDocuments.length > 0
      ? dbBookingsDocuments.reduce((latest, booking) => {
          return new Date(booking.date) > new Date(latest.date)
            ? booking
            : latest;
        }, dbBookingsDocuments[0])
      : null;

  // PWA CODE START
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  function startChromeInstall() {
    // console.log('deferredPrompt: ', deferredPrompt)
    if (deferredPrompt) {
      // console.log("deferredPrompt: ", deferredPrompt)
      deferredPrompt.prompt();
    }
    setinstallPopupDiv(false);
  }

  const [installPopupDiv, setinstallPopupDiv] = useState(true);
  const closeInstallPopup = () => {
    setinstallPopupDiv(false);
  };
  // PWA CODE END

  useEffect(() => {
    //First time set display mode from db to useState variable and also store the same value in localStorage
    // console.log('local storage value:', localStorage.getItem('mode'))
    if (
      localStorage.getItem("mode") === null ||
      localStorage.getItem("mode") === "null" ||
      localStorage.getItem("mode") === ""
    ) {
      // console.log('dbdisplaymode: ', dbDisplayModeDocuments && dbDisplayModeDocuments.displayMode)
      setCurrentModeStatus(
        dbDisplayModeDocuments && dbDisplayModeDocuments.displayMode
      );
      localStorage.setItem(
        "mode",
        dbDisplayModeDocuments && dbDisplayModeDocuments.displayMode
      );
    }
    // Function to handle changes in localStorage
    const handleStorageChange = () => {
      const storedValue = localStorage.getItem("mode");
      setCurrentModeStatus(storedValue);
      // console.log('currentModeStatus:', currentModeStatus)
    };

    // Call the function initially
    handleStorageChange();

    // Listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [dbDisplayModeDocuments]);

  // CODE FOR BOOK UPCOMING POPUP BOOKING
  const [popupVisible, setPopupVisible] = useState(false); // State to manage popup visibility

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  useEffect(() => {
    // Show popup after 5 seconds
    const timer = setTimeout(() => {
      setPopupVisible(true);
    }, 1000);

    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  }, []);
  return (
    <div className={currentModeStatus === "dark" ? "dark" : "light"}>
      <div className="App">
        {authIsReady && (
          <BrowserRouter>
            <div>
              {/* START UPCOMING BOOKINGS POPUP HERE */}
              {appointmentPermission &&
                appointmentPermission.permission == true && (
                  <>
                    <div>
                      <video
                        src="/assets/img/Doctors appointment booking.mp4"
                        autoPlay
                        loop
                        muted
                        style={{ display: "none" }}
                      />
                      {window.location.pathname !== "/" &&
                        ((
                          user && user.role === "admin"
                            ? dbBookingsDocuments &&
                              dbBookingsDocuments.length > 0
                            : userBookings && userBookings.length > 0
                        ) ? (
                          <div>
                            {popupVisible && (
                              <div className="book_upcoming_booking_popup">
                                <div className="book_upcoming_booking_popup-content">
                                  <div
                                    style={{
                                      backgroundColor: "var(--grey-light)",
                                      padding: "10px",
                                      borderRadius: "10px",
                                    }}
                                    className="d-flex align-items-center justify-content-center"
                                  >
                                    <div className="col-1">
                                      <img
                                        src="/assets/img/output-onlinegiftools.gif"
                                        alt="bell icon gif"
                                        style={{ width: "40px" }}
                                      ></img>
                                    </div>
                                    <div className="col-11">
                                      <h5>
                                        You Have&nbsp;
                                        <span
                                          style={{
                                            fontSize: "25px",
                                            color: "var(--pink)",
                                          }}
                                        >
                                          {user && user.role === "admin"
                                            ? dbBookingsDocuments &&
                                              dbBookingsDocuments.length
                                            : userBookings &&
                                              userBookings.length}
                                        </span>
                                        &nbsp;Upcoming Bookings
                                      </h5>
                                    </div>
                                  </div>
                                  <video
                                    src="/assets/img/Doctors appointment booking.mp4"
                                    autoPlay
                                    loop
                                    muted
                                    style={{ width: "90%" }}
                                  />
                                  <div className="upcoming-booking-popup-buttons">
                                    <button onClick={handleClosePopup}>
                                      Cancel
                                    </button>
                                    <Link
                                      to={
                                        dbBookingsDocuments.length > 0
                                          ? "/allcustomerbookings"
                                          : "products"
                                      }
                                    >
                                      <button
                                        onClick={() => {
                                          handleClosePopup();
                                        }}
                                        style={{
                                          backgroundColor: "var(--click-color)",
                                        }}
                                      >
                                        {dbBookingsDocuments.length > 0
                                          ? "My Bookings"
                                          : "Book Now"}
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          <>
                            {popupVisible && (
                              <div className="book_upcoming_booking_popup">
                                <div className="book_upcoming_booking_popup-content">
                                  <div
                                    style={{
                                      backgroundColor: "var(--grey-light)",
                                      padding: "10px",
                                      borderRadius: "10px",
                                    }}
                                    className="d-flex align-items-center justify-content-center"
                                  >
                                    <h5>
                                      Book your appointment now and enjoy the
                                      convenience!
                                    </h5>
                                  </div>

                                  <video
                                    src="/assets/img/Doctors appointment booking.mp4"
                                    autoPlay
                                    loop
                                    muted
                                    style={{ width: "90%" }}
                                  />
                                  <div className="upcoming-booking-popup-buttons">
                                    <button onClick={handleClosePopup}>
                                      Cancel
                                    </button>
                                    <Link to={user ? "/products" : "/login"}>
                                      <button
                                        onClick={() => {
                                          handleClosePopup();
                                        }}
                                        style={{
                                          backgroundColor: "var(--click-color)",
                                        }}
                                      >
                                        Book Now
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        ))}
                    </div>
                  </>
                )}

              {/* END UPCOMING BOOKINGS POPUP HERE */}

              {/* PWA INSTALLATION CODE START */}
              {dbTextContentDocuments &&
              dbTextContentDocuments.status == "active" ? (
                <>
                  {deferredPrompt && (
                    <div
                      className={
                        installPopupDiv
                          ? "install-popup-div open"
                          : "install-popup-div"
                      }
                    >
                      <div>
                        <span
                          onClick={closeInstallPopup}
                          className="material-symbols-outlined close-button"
                        >
                          close
                        </span>
                        <img
                          src="/assets/img/hc-logo.png"
                          alt="dentamax logo img"
                        ></img>
                        <h1>
                          For swift and efficient access, consider installing
                          the app on your device.
                        </h1>
                        <button id="btn_install" onClick={startChromeInstall}>
                          Install App
                        </button>
                      </div>
                    </div>
                  )}
                </>
              ) : null}
              {/* PWA INSTALLATION CODE END */}
              <Navbar />
              {/* Conditionally render icons based on user's role */}
              {user && user.role == "customer" && (
                <div>
                  <CallUsIcon />
                  <NotificationIcon />
                  {/* Render NotificationIcon for users */}
                </div>
              )}

              {user && user.role == "frontdesk" && <AddBookingIcon />}

              <div className={"full-content"}>
                <Routes>
                  <Route
                    path="/adminsettings"
                    element={<AdminSettings />}
                  ></Route>

                  <Route path="/" element={<PGHome />}></Route>

                  <Route path="/dermaads" element={<PGDermaAds />}></Route>
                  <Route path="/dental-ads" element={<PGDentalAds />}></Route>

                  <Route
                    path="/customerdashboard"
                    element={<PGCustomerDashboard />}
                  ></Route>

                  <Route exact path="/" element={<HomeHeroBanners />} />

                  <Route
                    path={user && user ? "/allcustomerbookings" : "/"}
                    element={<AllCustomerBookings />}
                  ></Route>
                  <Route
                    path="/admindashboard"
                    element={
                      user && user.role == "admin" ? (
                        <PGAdminDashboard />
                      ) : (
                        <Navigate to="/login" />
                      )
                    }
                  ></Route>
                  <Route
                    path="/frontdeskdashboard"
                    element={
                      user && user.role == "frontdesk" ? (
                        <PGFrontdeskDashboard />
                      ) : (
                        <Navigate to="/login" />
                      )
                    }
                  ></Route>

                  <Route
                    path="/adminuser"
                    element={
                      user && user.role == "admin" ? (
                        <PGAdminUser />
                      ) : (
                        <Navigate to="/login" />
                      )
                    }
                  ></Route>

                  <Route
                    path="/cosmetology"
                    element={
                      // user ?
                      <PGCosmetology />
                      // : <Navigate to="/login" />
                    }
                  ></Route>

                  <Route path="/gallery" element={<Gallery />}></Route>

                  <Route
                    path="/addbill"
                    element={
                      user && user.role === "admin" ? (
                        <AddBill />
                      ) : (
                        <Navigate to="/login" />
                      )
                    }
                  ></Route>

                  <Route
                    path="/addphoto"
                    element={
                      user && user.role === "admin" ? (
                        <AddPhoto />
                      ) : (
                        <Navigate to="/login" />
                      )
                    }
                  ></Route>

                  <Route
                    path="/adddocument"
                    element={
                      user && user.role === "admin" ? (
                        <AddDocument />
                      ) : (
                        <Navigate to="/login" />
                      )
                    }
                  ></Route>
                  <Route
                    path="/notifications"
                    element={
                      user && user ? <Notification /> : <Navigate to="/login" />
                    }
                  ></Route>
                  <Route
                    path="/setupslots/:productId"
                    element={
                      user && user.role === "admin" ? (
                        <SetupSlots />
                      ) : (
                        <Navigate to="/" />
                      )
                    }
                  ></Route>
                  <Route
                    path="/faq"
                    element={
                      <Faq />
                      // ) : (
                      //   <Navigate to="/login" />
                      // )
                    }
                  ></Route>
                  <Route path="/thank-you" element={<ThankYou />}></Route>
                  <Route
                    path="/privacy-policy"
                    element={
                      <PrivacyPolicy />
                      // ) : (
                      //   <Navigate to="/login" />
                      // )
                    }
                  ></Route>
                  <Route path="/products" element={<Products />}></Route>
                  <Route
                    path="/product-details/:productId"
                    element={<ProductDetails />}
                  />
                  <Route
                    path="/services-details/:productId"
                    element={<ServiceDetails />}
                  />
                  <Route
                    path="/doctor-details/:productId"
                    element={<DoctorDetails />}
                  />

                  <Route
                    path="/payment"
                    element={
                      <PGCustomerPayments />
                      // ) : (
                      //   <Navigate to="/login" />
                      // )
                    }
                  ></Route>

                  <Route
                    path="/more"
                    element={
                      // user ?
                      <More />
                      // : <Navigate to="/login" />
                    }
                  ></Route>
                  <Route
                    path="/about"
                    element={
                      // user ?
                      <About />
                      // : <Navigate to="/login" />
                    }
                  ></Route>

                  <Route
                    path="/contactus"
                    element={
                      // user ?
                      <ContactUs />
                      // : <Navigate to="/login" />
                    }
                  ></Route>
                  <Route
                    path="/disclaimer"
                    element={
                      // user ?
                      <Disclaimer />
                      // : <Navigate to="/login" />
                    }
                  ></Route>
                  <Route
                    path="/our-team"
                    element={
                      // user ?
                      <Team />
                      // : <Navigate to="/login" />
                    }
                  ></Route>
                  <Route
                    path="/services"
                    element={
                      // user ?
                      <Services />
                      // : <Navigate to="/login" />
                    }
                  ></Route>
                  <Route
                    path="/internationalpatients"
                    element={
                      // user ?
                      <InternationalPatients />
                      // : <Navigate to="/login" />
                    }
                  ></Route>

                  {/* <Route
                      path="/login"
                      element={user ? <Navigate to="/" /> : <PGLogin />}
                    ></Route> */}
                  <Route
                    path="/login"
                    element={user ? <Navigate to="/" /> : <PGLoginPhone />}
                  ></Route>
                  <Route
                    path="/login"
                    element={user ? <Navigate to="/" /> : <PGLoginPhone />}
                  ></Route>
                  <Route
                    path="/profile"
                    element={user ? <PGProfile /> : <Navigate to="/login" />}
                  />

                  <Route
                    path="/addbooking/:productId"
                    element={user ? <AddBooking /> : <PGLoginPhone />}
                  ></Route>
                  <Route
                    path="/calendarbooking"
                    element={<PGCalendarBooking />}
                  ></Route>
                </Routes>
              </div>

              <div style={{ marginLeft: "30px" }} className="section-title">
                <h2>We Accept All Major Insurances</h2>
              </div>
              <Insurances />
              <NavbarBottom></NavbarBottom>
              <FooterUpper></FooterUpper>
              <Footer />
            </div>
          </BrowserRouter>
        )}
      </div>
    </div>
  );
}

export default App;
