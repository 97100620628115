import React from "react";
import { Link } from "react-router-dom";
import "./FooterUpper.css";
const FooterUpper = () => {
  return (
    <div>
      <section
        className="footer_before relative"
        style={{
          backgroundImage: "url('./assets/img/our_team_bottom_bg.jpg')",
        }}
      >
        <div className="container">
          <div className="fb_inner">
            <h3 style={{ color: "var(--same-white-color)" }}>
              Contact and feel free to ask about more details
            </h3>
            <br />
            <div className="btn_group">
              <Link to="/contactus">
                <button className="theme_btn btn_fill">Contact Us</button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FooterUpper;
