import { useState } from "react";
import "./Filters.css";

const defaultFilterList = ["ALL", "ACTIVE", "INACTIVE"];
const defaultFilterLength = 0;

export default function Filters({ changeFilter, filterList, filterLength }) {
  let defaultFilterValue = filterList[0];
  const [currentFilter, setCurrentFilter] = useState(defaultFilterValue);

  if (!filterList) filterList = defaultFilterList;
  if (!filterLength) filterLength = defaultFilterLength;

  const handleClick = (newFilter) => {
    setCurrentFilter(newFilter);
    changeFilter(newFilter);
  };

  return (
    <div className="project-filter">
      <nav>
        {filterList.map((f) => (
          <button
            key={f}
            onClick={() => handleClick(f)}
            className={currentFilter === f ? "active" : ""}
          >
            <span>{f}</span>
            {currentFilter === f ? <small>{filterLength}</small> : ""}
          </button>
        ))}
      </nav>
    </div>
  );
}
