import React from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import { useDocument } from "../hooks/useDocument";
import Popup from "./Popup";
import { useEffect, useState } from "react";
import { useLogout } from "../hooks/useLogout";
import { useLocation } from "react-router-dom";
import DarkModeToggle from "react-dark-mode-toggle";
import { useFirestore } from "../hooks/useFirestore";

// import component
import FooterUpper from "./FooterUpper";
import "./More.css";

export default function More() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { user } = useAuthContext();
  // const { document: userDocument, error: userDocumentError } = useDocument('users', user.uid)
  //Popup Flags
  const [showPopupFlag, setShowPopupFlag] = useState(false);
  const [popupReturn, setPopupReturn] = useState(false);
  const { logout, isPending } = useLogout();
  //Popup Flags
  useEffect(() => {
    if (popupReturn) {
      logout();
    }
  }, [popupReturn]);

  const { document: appDisplayMode, error: appDisplayModeerror } = useDocument(
    "settings",
    "mode"
  );

  const { document: appointmentPermission } = useDocument(
    "settings",
    "appointment-permission"
  );
  const { updateDocument, deleteDocument } = useFirestore("settings");

  // START CODE FOR LIGHT/DARK MODE
  const toggleDarkMode = async (productId, currentModeStatus) => {
    // Calculate the new mode based on the current mode status
    const newDisplayMode = currentModeStatus === "light" ? "dark" : "light";

    // Update the mode in Firestore
    const updatedDocument = await updateDocument(productId, {
      displayMode: newDisplayMode,
    });

    // If the update is successful, update the local state
    if (updatedDocument && updatedDocument.success) {
      // setIsDarkMode(newDisplayMode === "dark");
      console.log("Mode status updated successfully");
    } else {
      console.error("Error updating mode status");
    }
  };

  // DARK\LIGHT CODE FOR CUSTOMER LOCAL STAORAGE
  const toggleUserDarkMode = async () => {
    // Retrieving a value from localStorage
    const currentModeStatus = localStorage.getItem("mode");

    const newDisplayMode = currentModeStatus === "light" ? "dark" : "light";
    // Storing a value in localStorage
    localStorage.setItem("mode", newDisplayMode);

    window.dispatchEvent(new Event("storage"));
  };
  // END CODE FOR LIGHT/DARK MODE

  //Popup Flags
  const showPopup = async (e) => {
    e.preventDefault();
    setShowPopupFlag(true);
    setPopupReturn(false);
  };
  return (
    <>
      {/* Popup Component */}
      <Popup
        showPopupFlag={showPopupFlag}
        setShowPopupFlag={setShowPopupFlag}
        setPopupReturn={setPopupReturn}
        msg={"Are you sure you want to logout?"}
      />
      <div className="more_contant_parnt_div">
        <div className="container">
          {appointmentPermission &&
            appointmentPermission.permission == true && (
              <>
                <div className="more-div-big-icon-div">
                  {user ? (
                    <div className="more-div-big-icon-div-inner pointer">
                      {user.role == "customer" ? (
                        <Link to="/customerdashboard">
                          <div style={{ margin: "auto" }}>
                            <span className="material-symbols-outlined">
                              dashboard
                            </span>
                          </div>
                          <h1 style={{ textAlign: "center" }}>Dashboard</h1>
                        </Link>
                      ) : user.role == "admin" ? (
                        <Link to="/customerdashboard">
                          <div style={{ margin: "auto" }}>
                            <span className="material-symbols-outlined">
                              dashboard
                            </span>
                          </div>
                          <h1 style={{ textAlign: "center" }}>Dashboard</h1>
                        </Link>
                      ) : user.role == "frontdesk" ? (
                        <Link to="/allcustomerbookings">
                          <div style={{ margin: "auto" }}>
                            <span className="material-symbols-outlined">
                              person
                            </span>
                          </div>
                          <h1 style={{ textAlign: "center" }}>
                            Manage Bookings
                          </h1>
                        </Link>
                      ) : user.role == "doctor" ? (
                        <Link to="/calendarbooking">
                          <div style={{ margin: "auto" }}>
                            <span className="material-symbols-outlined">
                              free_cancellation
                            </span>
                          </div>
                          <h1 style={{ textAlign: "center" }}>Calender</h1>
                        </Link>
                      ) : null}
                    </div>
                  ) : (
                    <div className="more-div-big-icon-div-inner pointer ">
                      <Link
                        to="/login"
                        className="d-flex align-items-center flex-column"
                      >
                        <div style={{ margin: "auto" }}>
                          <span className="material-symbols-outlined">
                            logout
                          </span>
                        </div>
                        <h1>Login</h1>
                      </Link>
                    </div>
                  )}

                  {/* SECOND ICON */}
                  {user ? (
                    <div className="more-div-big-icon-div-inner pointer">
                      {user.role == "customer" ? (
                        <Link to="/allcustomerbookings">
                          <div style={{ margin: "auto" }}>
                            <span className="material-symbols-outlined">
                              list_alt
                            </span>
                          </div>
                          <h1 style={{ textAlign: "center" }}>My Bookings</h1>
                        </Link>
                      ) : user.role == "admin" ? (
                        <Link to="/allcustomerbookings">
                          <div style={{ margin: "auto" }}>
                            <span className="material-symbols-outlined">
                              person
                            </span>
                          </div>
                          <h1 style={{ textAlign: "center" }}>
                            Manage Bookings
                          </h1>
                        </Link>
                      ) : user.role == "frontdesk" ? (
                        <Link to="">
                          <div style={{ margin: "auto" }}>
                            <span className="material-symbols-outlined">
                              account_balance_wallet
                            </span>
                          </div>
                          <h1 style={{ color: "black", textAlign: "center" }}>
                            Wallet
                          </h1>
                        </Link>
                      ) : user.role == "doctor" ? (
                        <Link to="/products">
                          <div style={{ margin: "auto" }}>
                            <span className="material-symbols-outlined">
                              edit_note
                            </span>
                          </div>
                          <h1 style={{ color: "black", textAlign: "center" }}>
                            Setup Slot
                          </h1>
                        </Link>
                      ) : null}
                    </div>
                  ) : (
                    <div className="more-div-big-icon-div-inner pointer">
                      <Link to="/products">
                        <div style={{ margin: "auto" }}>
                          <span className="material-symbols-outlined">
                            handshake
                          </span>
                        </div>
                        <h1 style={{ color: "black" }}>Appoinment</h1>
                      </Link>
                    </div>
                  )}

                  {/* Third icon */}
                  {user ? (
                    <div className="more-div-big-icon-div-inner pointer">
                      {user.role == "customer" ? (
                        <Link to="/contactus">
                          <div style={{ margin: "auto" }}>
                            <span class="material-symbols-outlined">
                              connect_without_contact
                            </span>
                          </div>
                          <h1 style={{ textAlign: "center" }}>Contact</h1>
                        </Link>
                      ) : user.role == "admin" ? (
                        <Link to="/contactus">
                          <div style={{ margin: "auto" }}>
                            <span class="material-symbols-outlined">
                              connect_without_contact
                            </span>
                          </div>
                          <h1 style={{ textAlign: "center" }}>Contact</h1>
                        </Link>
                      ) : user.role == "frontdesk" ? (
                        <Link to="">
                          <div style={{ margin: "auto" }}>
                            <span className="material-symbols-outlined">
                              account_balance_wallet
                            </span>
                          </div>
                          <h1 style={{ color: "black", textAlign: "center" }}>
                            Wallet
                          </h1>
                        </Link>
                      ) : user.role == "doctor" ? (
                        <Link to="/products">
                          <div style={{ margin: "auto" }}>
                            <span className="material-symbols-outlined">
                              edit_note
                            </span>
                          </div>
                          <h1 style={{ color: "black", textAlign: "center" }}>
                            Setup Slot
                          </h1>
                        </Link>
                      ) : null}
                    </div>
                  ) : (
                    <div className="more-div-big-icon-div-inner pointer">
                      <Link to="/contactus">
                        <div style={{ margin: "auto" }}>
                          <span className="material-symbols-outlined">
                            support
                          </span>
                        </div>
                        <h1 style={{ color: "black" }}>Contact Us</h1>
                      </Link>
                    </div>
                  )}
                </div>
              </>
            )}

          {/* if user not logged in */}
          {!user && (
            <div className="more-div-big-icon-div-inner pointer">
              <div className="more-expand-div">
                <div className="row">
                  <div className="col-md-6">
                    <div className="pp_card rightside leftside mlauto mauto">
                      <h6 className="more">Quick Links</h6>
                      <div className="">
                        <Link to="/">
                          <div className="myli_single bglight mt-3">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    Home
                                  </span>
                                </div>
                              </div>
                              <h6>
                                Home
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </div>
                        </Link>

                        <Link to="/services">
                          <div className="myli_single bglight pointer ">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    checklist
                                  </span>
                                </div>
                              </div>
                              <h6>
                                Services
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </div>
                        </Link>
                        <Link to="/our-team">
                          <div className="myli_single bglight mt-3 pointer">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    diversity_1
                                  </span>
                                </div>
                              </div>
                              <h6>
                                Our Team
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </div>
                        </Link>
                        <Link to="/internationalpatients">
                          <div className="myli_single bglight pointer ">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    public
                                  </span>
                                </div>
                              </div>
                              <h6>
                                International Patients
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </div>
                        </Link>

                        <div className="myli_single bglight mt-3 pointer">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  display_settings
                                </span>
                              </div>
                            </div>
                            <div
                              className="d-flex more_display_mode_card"
                              style={{ width: "100%" }}
                            >
                              <div>
                                <h6>Display Mode</h6>
                              </div>
                              <div>
                                <DarkModeToggle
                                  onChange={() => toggleUserDarkMode()}
                                  checked={
                                    localStorage.getItem("mode") === "dark"
                                  }
                                  size={50}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="pp_card rightside mauto">
                      <div className="">
                        <h6 className="more">More Links</h6>

                        <Link to="/faq">
                          <div className="myli_single bglight mt-3 pointer">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    quiz
                                  </span>
                                </div>
                              </div>
                              <h6>
                                FAQ
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </div>
                        </Link>

                        <Link to="/about">
                          <div className="myli_single bglight mt-3">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    info
                                  </span>
                                </div>
                              </div>
                              <h6>
                                About Us
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </div>
                        </Link>
                        <Link to="/contactus">
                          <div className="myli_single bglight">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    support
                                  </span>
                                </div>
                              </div>
                              <h6>
                                Contact Us
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </div>
                        </Link>
                        <Link to="/privacy-policy">
                          <div className="myli_single bglight mt-3 pointer">
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    info
                                  </span>
                                </div>
                              </div>
                              <h6>
                                Privacy Policy
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </div>
                        </Link>
                        {appointmentPermission &&
                          appointmentPermission.permission == true && (
                            <Link to="/login">
                              <div className="myli_single bglight mt-3 pointer">
                                <div className="profile-page-content">
                                  <div className="icon">
                                    <div className="icon_bg">
                                      <span class="material-symbols-outlined">
                                        logout
                                      </span>
                                    </div>
                                  </div>
                                  <h6>
                                    Sign Up
                                    <span class="material-symbols-outlined">
                                      chevron_right
                                    </span>
                                  </h6>
                                </div>
                              </div>
                            </Link>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* section for 'admin' user role  */}
          {user && user.role === "admin" && (
            <div className="more-expand-div">
              <div className="row">
                {/* <div className="col-md-6">
                <div className="pp_card rightside mlauto mauto">
                  <div className="">
                    <h6 className="more">Bookings</h6>
                    <div className="myli_single bglight pointer">
                      <div className="profile-page-content">
                        <div className="icon">
                          <div className="icon_bg">
                            <span class="material-symbols-outlined">
                              add_shopping_cart
                            </span>
                          </div>
                        </div>
                        <h6>
                          Add Bookings
                          <span class="material-symbols-outlined">
                            chevron_right
                          </span>
                        </h6>
                      </div>
                    </div>
                    <div className="myli_single bglight mt-3 pointer">
                      <div className="profile-page-content">
                        <div className="icon">
                          <div className="icon_bg">
                            <span class="material-symbols-outlined">
                              shopping_bag
                            </span>
                          </div>
                        </div>
                        <h6>
                          Manage Bookings
                          <span class="material-symbols-outlined">
                            chevron_right
                          </span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
                {/* <div className="col-md-6">
                <div className="pp_card rightside">
                  <div className="">
                    <h6 className="more">Payment</h6>
                    <div className="myli_single bglight">
                      <div className="profile-page-content">
                        <div className="icon">
                          <div className="icon_bg">
                            <span class="material-symbols-outlined">
                              payments
                            </span>
                          </div>
                        </div>
                        <h6>
                          Wallet
                          <span class="material-symbols-outlined">
                            chevron_right
                          </span>
                        </h6>
                      </div>
                    </div>
                    <div className="myli_single bglight mt-3">
                      <div className="profile-page-content">
                        <div className="icon">
                          <div className="icon_bg">
                            <span class="material-symbols-outlined">
                              credit_card
                            </span>
                          </div>
                        </div>
                        <h6>
                          Due Payments
                          <span class="material-symbols-outlined">
                            chevron_right
                          </span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
                <div className="col-md-6 mt-4">
                  <div className="pp_card rightside mlauto mauto">
                    <div className="">
                      <h6 className="more">Quick Links</h6>
                      <Link to="/adminuser">
                        <div className="myli_single bglight">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  groups
                                </span>
                              </div>
                            </div>
                            <h6>
                              All Users
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Link>
                      <Link to="/allcustomerbookings">
                        <div className="myli_single bglight mt-3">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  local_activity
                                </span>
                              </div>
                            </div>
                            <h6>
                              All Bookings
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Link>

                      <Link to="/internationalpatients">
                        <div className="myli_single bglight mt-3">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  flight
                                </span>
                              </div>
                            </div>
                            <h6>
                              International Patients
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Link>
                      <Link to="/gallery">
                        <div className="myli_single bglight mt-3">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  photo_library
                                </span>
                              </div>
                            </div>
                            <h6>
                              Gallery
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Link>
                      <div className="myli_single bglight mt-3 pointer">
                        <div className="profile-page-content">
                          <div className="icon">
                            <div className="icon_bg">
                              <span class="material-symbols-outlined">
                                display_settings
                              </span>
                            </div>
                          </div>
                          <div
                            className="d-flex more_display_mode_card"
                            style={{ width: "100%" }}
                          >
                            <div>
                              <h6>Display Mode</h6>
                            </div>
                            <div>
                              {user && user.role == "admin" ? (
                                <>
                                  <DarkModeToggle
                                    onChange={() =>
                                      toggleDarkMode(
                                        appDisplayMode && appDisplayMode.id,
                                        appDisplayMode &&
                                          appDisplayMode.displayMode
                                      )
                                    }
                                    checked={
                                      appDisplayMode &&
                                      appDisplayMode.displayMode == "dark"
                                    }
                                    size={50}
                                  />
                                </>
                              ) : (
                                <>
                                  <DarkModeToggle
                                    onChange={() => toggleUserDarkMode()}
                                    checked={
                                      localStorage.getItem("mode") === "dark"
                                    }
                                    size={50}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mt-4">
                  <div className="pp_card rightside">
                    <div className="">
                      <h6 className="more">Site Settings</h6>
                      <Link to="/">
                        <div className="myli_single bglight mt-3">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  home
                                </span>
                              </div>
                            </div>
                            <h6>
                              Manage Home
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Link>
                      <Link to="/our-team">
                        <div className="myli_single bglight mt-3">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  group_add
                                </span>
                              </div>
                            </div>
                            <h6>
                              Manage Team
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Link>
                      <Link to="/services">
                        <div className="myli_single bglight mt-3">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  category
                                </span>
                              </div>
                            </div>
                            <h6>
                              Manage Services
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Link>
                      <Link to="/products">
                        <div className="myli_single bglight mt-3">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  inventory
                                </span>
                              </div>
                            </div>
                            <h6>
                              Manage Products
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-6 mt-4">
                <div className="pp_card rightside mlauto mauto">
                  <div className="">
                    <h6 className="more">More</h6>
                    <div
                      className="myli_single bglight pointer"
                      onClick={showPopup}
                    >
                      <div className="profile-page-content">
                        <div className="icon">
                          <div className="icon_bg">
                            <span class="material-symbols-outlined">
                              logout
                            </span>
                          </div>
                        </div>
                        <h6>
                          Logout
                          <span class="material-symbols-outlined">
                            chevron_right
                          </span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              </div>

              <br />
            </div>
          )}
          {/* section for 'customer' user role  */}
          {user && user.role === "customer" && (
            <div className="more-expand-div">
              <div className="row">
                <div className="col-md-6">
                  <div className="pp_card rightside leftside mlauto mauto">
                    <h6 className="more">Quick Links</h6>
                    <div className="">
                      <Link to="/">
                        <div className="myli_single bglight mt-3">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  Home
                                </span>
                              </div>
                            </div>
                            <h6>
                              Home
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Link>

                      <Link to="/services">
                        <div className="myli_single bglight pointer ">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  checklist
                                </span>
                              </div>
                            </div>
                            <h6>
                              Services
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Link>

                      <Link to="/internationalpatients">
                        <div className="myli_single bglight pointer ">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  public
                                </span>
                              </div>
                            </div>
                            <h6>
                              International Patients
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Link>
                      <Link to="/gallery">
                        <div className="myli_single bglight pointer">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  photo_library
                                </span>
                              </div>
                            </div>
                            <h6>
                              Gallery
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Link>
                      {appointmentPermission &&
                        appointmentPermission.permission == true && (
                          <Link to="/products">
                            <div className="myli_single bglight pointer">
                              <div className="profile-page-content">
                                <div className="icon">
                                  <div className="icon_bg">
                                    <span class="material-symbols-outlined">
                                      add_shopping_cart
                                    </span>
                                  </div>
                                </div>
                                <h6>
                                  Add Bookings
                                  <span class="material-symbols-outlined">
                                    chevron_right
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </Link>
                        )}
                      {appointmentPermission &&
                        appointmentPermission.permission == true && (
                          <Link to="/allcustomerbookings">
                            <div className="myli_single bglight pointer">
                              <div className="profile-page-content">
                                <div className="icon">
                                  <div className="icon_bg">
                                    <span class="material-symbols-outlined">
                                      list_alt
                                    </span>
                                  </div>
                                </div>
                                <h6>
                                  My Bookings
                                  <span class="material-symbols-outlined">
                                    chevron_right
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </Link>
                        )}
                      <Link to="/our-team">
                        <div className="myli_single bglight mt-3 pointer">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  diversity_1
                                </span>
                              </div>
                            </div>
                            <h6>
                              Our Team
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pp_card rightside mauto">
                    <div className="">
                      <h6 className="more">More Links</h6>

                      {appointmentPermission &&
                        appointmentPermission.permission == true && (
                          <Link to="/customerdashboard">
                            <div className="myli_single bglight pointer ">
                              <div className="profile-page-content">
                                <div className="icon">
                                  <div className="icon_bg">
                                    <span class="material-symbols-outlined">
                                      dashboard
                                    </span>
                                  </div>
                                </div>
                                <h6>
                                  Dashboard
                                  <span class="material-symbols-outlined">
                                    chevron_right
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </Link>
                        )}
                      <Link to="/faq">
                        <div className="myli_single bglight mt-3 pointer">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  quiz
                                </span>
                              </div>
                            </div>
                            <h6>
                              FAQ
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Link>

                      <Link to="/about">
                        <div className="myli_single bglight mt-3">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  info
                                </span>
                              </div>
                            </div>
                            <h6>
                              About Us
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Link>
                      <Link to="/contactus">
                        <div className="myli_single bglight">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  support
                                </span>
                              </div>
                            </div>
                            <h6>
                              Contact Us
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Link>
                      <Link to="/privacy-policy">
                        <div className="myli_single bglight mt-3 pointer">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  info
                                </span>
                              </div>
                            </div>
                            <h6>
                              Privacy Policy
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Link>
                      {/* <Link to="/customerbooking">
                      <div className="myli_single bglight mt-3 pointer">
                        <div className="profile-page-content">
                          <div className="icon">
                            <div className="icon_bg">
                              <span class="material-symbols-outlined">
                                add_shopping_cart
                              </span>
                            </div>
                          </div>
                          <h6>
                            My Bookings
                            <span class="material-symbols-outlined">
                              chevron_right
                            </span>
                          </h6>
                        </div>
                      </div>
                    </Link> */}
                      <div className="myli_single bglight mt-3 pointer">
                        <div className="profile-page-content">
                          <div className="icon">
                            <div className="icon_bg">
                              <span class="material-symbols-outlined">
                                display_settings
                              </span>
                            </div>
                          </div>
                          <div
                            className="d-flex more_display_mode_card"
                            style={{ width: "100%" }}
                          >
                            <div>
                              <h6>Display Mode</h6>
                            </div>
                            <div>
                              <DarkModeToggle
                                onChange={() => toggleUserDarkMode()}
                                checked={
                                  localStorage.getItem("mode") === "dark"
                                }
                                size={50}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {appointmentPermission &&
                        appointmentPermission.permission == true && (
                          <div
                            className="myli_single bglight pointer"
                            onClick={showPopup}
                          >
                            <div className="profile-page-content">
                              <div className="icon">
                                <div className="icon_bg">
                                  <span class="material-symbols-outlined">
                                    logout
                                  </span>
                                </div>
                              </div>
                              <h6>
                                Logout
                                <span class="material-symbols-outlined">
                                  chevron_right
                                </span>
                              </h6>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* section for 'frontdesk' user role  */}
          {user && user.role === "frontdesk" && (
            <div className="more-expand-div more-frontdesk-expand-div">
              <div className="row">
                <div className="col-md-6">
                  <div className="pp_card rightside  mlauto mauto">
                    <div className="">
                      <h6 className="more">Bookings</h6>
                      <div className="myli_single bglight pointer">
                        <Link to="/products">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  add_shopping_cart
                                </span>
                              </div>
                            </div>
                            <h6>
                              Add Bookings
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </Link>
                      </div>
                      <div className="myli_single bglight mt-3 pointer">
                        <Link to="/frontdeskdashboard">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  shopping_bag
                                </span>
                              </div>
                            </div>
                            <h6>
                              Manage Bookings
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="pp_card rightside">
                    <div className="">
                      <h6 className="more">Payment</h6>
                      <div className="myli_single bglight">
                        <div className="profile-page-content">
                          <div className="icon">
                            <div className="icon_bg">
                              <span class="material-symbols-outlined">
                                payments
                              </span>
                            </div>
                          </div>
                          <h6>
                            Wallet
                            <span class="material-symbols-outlined">
                              chevron_right
                            </span>
                          </h6>
                        </div>
                      </div>
                      <div className="myli_single bglight mt-3">
                        <div className="profile-page-content">
                          <div className="icon">
                            <div className="icon_bg">
                              <span class="material-symbols-outlined">
                                credit_card
                              </span>
                            </div>
                          </div>
                          <h6>
                            Due Payments
                            <span class="material-symbols-outlined">
                              chevron_right
                            </span>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mt-4">
                  <div className="pp_card rightside mlauto mauto">
                    <div className="">
                      <h6 className="more">General Links</h6>
                      <Link to="/services">
                        <div className="myli_single bglight pointer ">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  checklist
                                </span>
                              </div>
                            </div>
                            <h6>
                              Services
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Link>
                      <Link to="/internationalpatients">
                        <div className="myli_single bglight pointer ">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  public
                                </span>
                              </div>
                            </div>
                            <h6>
                              International Patients
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Link>
                      <Link to="/our-team">
                        <div className="myli_single bglight mt-3 pointer">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  diversity_1
                                </span>
                              </div>
                            </div>
                            <h6>
                              Our Team
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mt-4">
                  <div className="pp_card rightside">
                    <div className="">
                      <h6 className="more">Quick Links</h6>
                      <Link to="/privacy-policy">
                        <div className="myli_single bglight mt-3 pointer">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  info
                                </span>
                              </div>
                            </div>
                            <h6>
                              Privacy Policy
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Link>
                      <Link to="/about">
                        <div className="myli_single bglight mt-3">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  info
                                </span>
                              </div>
                            </div>
                            <h6>
                              About Us
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Link>
                      <Link to="/contactus">
                        <div className="myli_single bglight">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  support
                                </span>
                              </div>
                            </div>
                            <h6>
                              Contact Us
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-6 mt-4">
                <div className="pp_card rightside mlauto mauto">
                  <div className="">
                    <h6 className="more">More</h6>
                    <div
                      className="myli_single bglight pointer"
                      onClick={showPopup}
                    >
                      <div className="profile-page-content">
                        <div className="icon">
                          <div className="icon_bg">
                            <span class="material-symbols-outlined">
                              logout
                            </span>
                          </div>
                        </div>
                        <h6>
                          Logout
                          <span class="material-symbols-outlined">
                            chevron_right
                          </span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
                <div className="col-md-6 mt-4">
                  <div className="pp_card rightside">
                    <div className="">
                      <h6 className="more">User</h6>
                      <div className="myli_single bglight">
                        <Link to="/frontdeskdashboard">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  person
                                </span>
                              </div>
                            </div>
                            <h6>
                              All Users
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br />
            </div>
          )}
          {/* section for 'doctor' user role  */}
          {user && user.role === "doctor" && (
            <div className="more-expand-div more-frontdesk-expand-div">
              <div className="row">
                <div className="col-md-12">
                  <div className="pc_card rightside  mlauto mauto">
                    <div className="">
                      <h6 className="more">Bookings</h6>
                      <div className="myli_single bglight pointer">
                        <Link to="/calendarbooking">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  free_cancellation
                                </span>
                              </div>
                            </div>
                            <h6 style={{ color: "black" }}>
                              Calendar
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </Link>
                      </div>
                      <div className="myli_single bglight mt-3 pointer">
                        <Link to="/products">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  edit_note
                                </span>
                              </div>
                            </div>
                            <h6 style={{ color: "black" }}>
                              Setup Slots
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="pp_card rightside">
                  <div className="">
                    <h6 className="more">Payment</h6>
                    <div className="myli_single bglight">
                      <div className="profile-page-content">
                        <div className="icon">
                          <div className="icon_bg">
                            <span class="material-symbols-outlined">
                              payments
                            </span>
                          </div>
                        </div>
                        <h6 style={{color:"black"}}>
                          Wallet
                          <span class="material-symbols-outlined">
                            chevron_right
                          </span>
                        </h6>
                      </div>
                    </div>
                    <div className="myli_single bglight mt-3">
                      <div className="profile-page-content">
                        <div className="icon">
                          <div className="icon_bg">
                            <span class="material-symbols-outlined">
                              credit_card
                            </span>
                          </div>
                        </div>
                        <h6 style={{color:"black"}}>
                          Due Payments
                          <span class="material-symbols-outlined">
                            chevron_right
                          </span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="row">
                <div className="col-md-6 mt-4">
                  <div className="pc_card rightside mlauto mauto">
                    <div className="">
                      <h6 className="more">General Links</h6>
                      <Link to="/services">
                        <div className="myli_single bglight pointer ">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  checklist
                                </span>
                              </div>
                            </div>
                            <h6 style={{ color: "black" }}>
                              Services
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Link>
                      <Link to="/internationalpatients">
                        <div className="myli_single bglight pointer ">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  public
                                </span>
                              </div>
                            </div>
                            <h6 style={{ color: "black" }}>
                              International Patients
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Link>
                      <Link to="/our-team">
                        <div className="myli_single bglight mt-3 pointer">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  diversity_1
                                </span>
                              </div>
                            </div>
                            <h6 style={{ color: "black" }}>
                              Our Team
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mt-4">
                  <div className="pc_card rightside">
                    <div className="">
                      <h6 className="more">Quick Links</h6>
                      <Link to="/privacy-policy">
                        <div className="myli_single bglight mt-3 pointer">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  info
                                </span>
                              </div>
                            </div>
                            <h6 style={{ color: "black" }}>
                              Privacy Policy
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Link>
                      <Link to="/about">
                        <div className="myli_single bglight mt-3">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  info
                                </span>
                              </div>
                            </div>
                            <h6 style={{ color: "black" }}>
                              About Us
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Link>
                      <Link to="/contactus">
                        <div className="myli_single bglight">
                          <div className="profile-page-content">
                            <div className="icon">
                              <div className="icon_bg">
                                <span class="material-symbols-outlined">
                                  support
                                </span>
                              </div>
                            </div>
                            <h6 style={{ color: "black" }}>
                              Contact Us
                              <span class="material-symbols-outlined">
                                chevron_right
                              </span>
                            </h6>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-6 mt-4">
                <div className="pp_card rightside mlauto mauto">
                  <div className="">
                    <h6 className="more">More</h6>
                    <div
                      className="myli_single bglight pointer"
                      onClick={showPopup}
                    >
                      <div className="profile-page-content">
                        <div className="icon">
                          <div className="icon_bg">
                            <span class="material-symbols-outlined">
                              logout
                            </span>
                          </div>
                        </div>
                        <h6>
                          Logout
                          <span class="material-symbols-outlined">
                            chevron_right
                          </span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
                {/* <div className="col-md-6 mt-4">
                <div className="pp_card rightside">
                  <div className="">
                    <h6 className="more">User</h6>
                    <div className="myli_single bglight">
                      <Link to="/frontdeskdashboard">
                        <div className="profile-page-content">
                          <div className="icon">
                            <div className="icon_bg">
                              <span class="material-symbols-outlined">
                                person
                              </span>
                            </div>
                          </div>
                          <h6>
                            All Users
                            <span class="material-symbols-outlined">
                              chevron_right
                            </span>
                          </h6>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div> */}
              </div>

              <br />
            </div>
          )}
          <br />
          <br />
        </div>
      </div>
    </>
  );
}
