import React from "react";
import OwlCarousel from "react-owl-carousel"; // Import OwlCarousel
import "./Insurances.css"; // Ensure to import Owl Carousel CSS in your main styles
import { Autoplay } from "swiper";

const Insurances = () => {
  // Define options for the Owl Carousel
  const options = {
    loop: true,
    margin: 10,
    dots: false,
    nav: false,
    autoplay: true,
    responsive: {
      0: { items: 2 }, // 2 items on small screens
      600: { items: 4 }, // 3 items on medium screens
      1000: { items: 5 }, // 4 items on large screens
    },
  };

  return (
    <div className="insurance-container">
      <OwlCarousel className="owl-theme d-flex align-items-center" {...options}>
        <div className="insurance-image-wrapper">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/dentamax-prod.appspot.com/o/insurances%20logo%2Fneuron.png?alt=media&token=cd894cf2-96db-4d33-a0dd-5b308b8c32e5"
            alt="insurance img 1"
            className="insurance-image"
          />
        </div>
        <div className="insurance-image-wrapper">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/dentamax-prod.appspot.com/o/insurances%20logo%2Falmadallah.png?alt=media&token=66d28e8a-6316-4179-a6fb-0e3ace84e40e"
            alt="insurance img 2"
            className="insurance-image"
          />
        </div>
        <div className="insurance-image-wrapper">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/dentamax-prod.appspot.com/o/insurances%20logo%2Fnextcare.png?alt=media&token=caeb935d-bdbd-4024-bb6b-dc413c5311f0"
            alt="insurance img 3"
            className="insurance-image"
          />
        </div>
        <div className="insurance-image-wrapper">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/dentamax-prod.appspot.com/o/insurances%20logo%2Flifline.png?alt=media&token=7be4b079-d882-462a-a691-7f99497ca02b"
            alt="insurance img 4"
            className="insurance-image"
          />
        </div>
        <div className="insurance-image-wrapper">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/dentamax-prod.appspot.com/o/insurances%20logo%2Fadnic.png?alt=media&token=54b6ad30-dcdd-42dd-9394-36bbfd2ed380"
            alt="insurance img 5"
            className="insurance-image"
          />
        </div>
        <div className="insurance-image-wrapper">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/dentamax-prod.appspot.com/o/insurances%20logo%2Fglobemed.png?alt=media&token=4fd5e3f0-64b9-45e6-b991-74622810a3c5"
            alt="insurance img 6"
            className="insurance-image"
          />
        </div>
        <div className="insurance-image-wrapper">
          <img
            src="/assets/img/insurence-img1.png"
            alt="insurance img 7"
            className="insurance-image"
          />
        </div>
        <div className="insurance-image-wrapper">
          <img
            src="/assets/img/insurence-img2.png"
            alt="insurance img 8"
            className="insurance-image"
          />
        </div>
      </OwlCarousel>
    </div>
  );
};

export default Insurances;
