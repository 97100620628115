import React, { useState, useEffect } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import {
  projectFirestore,
  projectStorage,
  timestamp,
} from "../firebase/config";
import { useLocation } from "react-router-dom";
import { useCollection } from "../hooks/useCollection";
import "./Services.css";
import { Link } from "react-router-dom";

import { Dropdown } from "react-bootstrap";
import "./Services.css";

// Importing owl carousel for styling (if needed)
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const PGCosmetology = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { user } = useAuthContext();

  // Fetch Cosmetology services from Firestore
  const { documents: CosmeServices, error: CosmeServicesError } = useCollection(
    "services",
    ["service", "==", "Cosmetology"]
  );

  const [isEditing, setIsEditing] = useState(false);
  const [productName, setProductName] = useState("Add Sub Category");
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedService, setselectedService] = useState("Select Service");
  const [selectedCategory, setselectedCategory] = useState("Select Category");
  // Create a state variable for sub-services based on the selected service
  const [subServices, setSubServices] = useState([]);
  // Initialize a state variable to track the active section
  const updateSubServices = (service) => {
    // Use a mapping or switch statement to set sub-services for each service
    let updatedSubServices = [];
    switch (service) {
      case "Cosmetology":
        updatedSubServices = ["Aesthetic Care"];
        break;
      default:
        updatedSubServices = [];
    }

    // Update the sub-services state
    setSubServices(updatedSubServices);
  };

  const [activeSection, setActiveSection] = useState("Hydrafacial");

  const handleCategoryChange = (event) => {
    setselectedCategory(event);
  };
  const handleServiceChange = (event) => {
    const selectedService = event;
    setselectedService(selectedService);
    // Update sub-services based on the selected service
    updateSubServices(selectedService);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const handleNameChange = (event) => {
    setProductName(event.target.value);
  };

  const handleNameBlur = () => {
    setIsEditing(false);
  };

  const handleNameClick = () => {
    setIsEditing(true);
    // Reset the product name input field when clicked
    setProductName("");
  };

  const handleAddProduct = async () => {
    // Validation: Check if a service is selected
    if (selectedService === "Select Service") {
      alert("Please Select a Service.");
      return;
    }

    // Validation: Check if product name is provided
    if (!productName || productName === "Service Name") {
      alert("Please Provide Service Name.");
      return;
    }

    // Validation: Check if a service is selected
    if (selectedCategory === "Select Category") {
      alert("Please Select a Category.");
      return;
    }

    try {
      // Create a new product document in the "products" collection
      const newProductRef = await projectFirestore.collection("services").add({
        sub_service: productName,
        price: "Price Not Available",
        service: selectedService,
        category: selectedCategory,
        discount: "Discount Not Available",
        rating: "Rating Not Available",
        status: "active",
        short_description: "Add Short Description",
        description: "Add Full Description About Product",
        createdAt: timestamp.fromDate(new Date()),
        // Add other fields as needed (e.g., price, description, etc.)
      });

      // Perform actions with the selected image, e.g., store in local storage
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageData = reader.result;
        // Store imageData in local storage or handle as needed
        localStorage.setItem("productImage", imageData);
      };
      reader.readAsDataURL(selectedImage);

      // Upload the image to the "product_img" folder in Firebase Storage
      if (selectedImage) {
        // Get the ID of the newly created document
        const newProductId = newProductRef.id;

        // Use the document ID as the folder name
        const storageRef = projectStorage.ref(`product_img/${newProductId}`);

        // Upload the image to the created folder in Firebase Storage
        const imageRef = storageRef.child(selectedImage.name);
        await imageRef.put(selectedImage);

        // Get the URL of the uploaded image
        const imageUrl = await imageRef.getDownloadURL();

        // Update the document with the image URL
        await newProductRef.update({
          product_img_1: imageUrl,
        });
      }

      // Reset product name and selected image after adding the product
      setProductName("Service Name");
      setSelectedImage(null);
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  // Reset product name and selected image
  const handleCancel = () => {
    setProductName("Service Name");
    setSelectedImage(null);
  };

  const groupedServices = CosmeServices
    ? CosmeServices.reduce((acc, service) => {
        const category = service.category || "Uncategorized";
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(service);
        return acc;
      }, {})
    : {};

  // Function to handle the service click and scroll to "our-services-heading" if a specific service is clicked
  const handleServiceClick = (service) => {
    setActiveSection(service);
    if (
      service === "Hydrafacial" ||
      service === "ChemicalPeel" ||
      service === "OxygeneoFacial" ||
      service === "LaserHairRemoval"
    ) {
      scrollToOurServicesHeading();
    }
  };

  // Function to scroll to the "our-services-heading" element
  const scrollToOurServicesHeading = () => {
    const ourServicesHeading = document.getElementById("our-services-heading");
    if (ourServicesHeading) {
      ourServicesHeading.scrollIntoView({ behavior: "smooth" });
    }
  };

  const dental_services_carousel_option = {
    items: 4,
    dots: false,
    loop: true,
    margin: 10,
    nav: false,
    smartSpeed: 1500,
    autoplay: true,
    autoplayTimeout: 9000,
    responsive: {
      0: {
        items: 2,
      },
      768: {
        items: 3,
      },
      1200: {
        items: 4,
      },
    },
  };

  // youtube videos
  const youtubeVideos = [
    "https://youtu.be/eiFhpJOj6DY?si=Yoctborkb6RAOm4L",
    "https://youtu.be/NoZjuYI0laQ?si=J-WSFkpnEev8XlnG",
    "https://youtu.be/nNeMhng8WaM?si=M4ILS_-CyJVRqHB1",
    "https://youtube.com/shorts/Jp5o4yJPiOo?si=e30rrpGJca9lZe_y",
  ];

  const getEmbedUrl = (mediaUrl) => {
    if (typeof mediaUrl === "string") {
      // Check for YouTube shorts URL
      const shortsMatch = mediaUrl.match(/youtube\.com\/shorts\/([\w-]+)\??/);
      if (shortsMatch) {
        const mediaId = shortsMatch[1];
        return `https://www.youtube.com/embed/${mediaId}`;
      }

      // Check for regular YouTube media URL
      const regularMatch = mediaUrl.match(/youtu\.be\/([\w-]+)/);
      if (regularMatch) {
        const mediaId = regularMatch[1];
        return `https://www.youtube.com/embed/${mediaId}`;
      }
    }
    // Add other social platforms as needed
    // Example for Vimeo: if (mediaUrl.includes("vimeo.com/")) return "vimeo embed URL";
    // Default case
    return mediaUrl;
  };

  return (
    <div>
      <div className="pg_contact_us">
        <div className="banner relative">
          <img src="/assets/img/cosmetology-banner.png" alt="Banner" />
          <div className="banner_content">
            <div className="bc_inner">
              <div className="container">
                <h3 className="banner_subheading">Skin Treatments</h3>
                <h2 className="services_banner_heading">
                  Ready for Beautiful Results?
                </h2>
                <Link to="/contactus">
                  <button className="btn_fill">Book an appointment</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* icons services section */}
      <div style={{ marginTop: "70px" }}>
        <div className="container-fluid">
          <div className="row">
            {/* left section */}
            <div className="col col-md-12 col-sm-12 col-lg-6">
              <div className="row">
                <h2 className="service_selct_choice">Select By Choice</h2>
              </div>
              <div className="services_icon_div">
                <div className="row">
                  <div
                    className={`col col-md-6 col-sm-6 icon_top_row first_service_icon ${
                      activeSection === "Hydrafacial" ? "active-service" : ""
                    }`}
                    style={{
                      borderRight: "1px solid gray",
                      borderBottom: "1px solid gray",
                    }}
                    onClick={() => handleServiceClick("Hydrafacial")}
                  >
                    <img
                      src="/assets/img/Hydrafacial.png"
                      className="services_icon_images"
                    />
                    <h5>Hydrafacial</h5>
                  </div>
                  <div
                    className={`col col-md-6 col-sm-6 icon_top_row first_service_icon ${
                      activeSection === "ChemicalPeel" ? "active-service" : ""
                    }`}
                    style={{
                      borderBottom: "1px solid gray",
                    }}
                    onClick={() => handleServiceClick("ChemicalPeel")}
                  >
                    <img
                      src="/assets/img/Chemical Peel.png"
                      className="services_icon_images"
                    />
                    <h5>Chemical Peel</h5>
                  </div>
                </div>
                <div className="row">
                  <div
                    className={`col col-md-6 col-sm-6 icon_top_row first_service_icon ${
                      activeSection === "OxygeneoFacial" ? "active-service" : ""
                    }`}
                    style={{
                      borderRight: "1px solid gray",
                    }}
                    onClick={() => handleServiceClick("OxygeneoFacial")}
                  >
                    <img
                      src="/assets/img/Oxygeneo Facial.png"
                      className="services_icon_images"
                    />
                    <h5>Oxygeneo Facial</h5>
                  </div>
                  <div
                    className={`col col-md-6 col-sm-6 icon_top_row first_service_icon ${
                      activeSection === "LaserHairRemoval"
                        ? "active-service"
                        : ""
                    }`}
                    onClick={() => handleServiceClick("LaserHairRemoval")}
                  >
                    <img
                      src="/assets/img/Laser hair removal.png"
                      className="services_icon_images"
                    />
                    <h5>Laser Hair Removal</h5>
                  </div>
                </div>
              </div>
            </div>

            {/* right section */}
            <div className="col col-md-12 col-sm-12 col-lg-6 right_row_parent_div">
              <div style={{ position: "relative" }}>
                <div>
                  <img
                    src="./assets/img/Ms. Leo.png"
                    alt="direction_girl"
                    className="direction_girl"
                  />
                  <div className="direction_girl_dr_name">
                    <h2>MS. LEO</h2>
                    <p>AESTHETICIAN / BEAUTY / LASER SPECIALIST</p>
                  </div>
                </div>
                <img
                  src="./assets/img/sevices_direction_circle.webp"
                  alt="direction_girl"
                  className="sevices_direction_circle"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add New Service */}
      {user && user.role == "admin" && (
        <div className="container">
          <div className="row">
            <div className="add_new_service_sec_parent">
              <div className="add_new_srvic_heading">
                <span
                  class="material-symbols-outlined"
                  style={{ color: "white" }}
                >
                  concierge
                </span>{" "}
                &nbsp;
                <h4>Add New Service</h4>
              </div>
              <div className="new_service_details_parent">
                <div className="row">
                  <div className="col-md-4 col-lg-4">
                    <div className="new_service_img_div">
                      <div className="doctor-top d-flex align-items-start justify-content-start">
                        {selectedImage ? (
                          <img
                            src={URL.createObjectURL(selectedImage)}
                            alt="Product"
                          />
                        ) : (
                          <img
                            src="https://securitysolutions.me/wp-content/uploads/2018/12/dummy-person.jpg"
                            alt="Product"
                          />
                        )}
                        <div className="new_service_add_img_btn">
                          <div>
                            <input
                              type="file"
                              accept="image/*"
                              id="imageInput"
                              onChange={handleImageChange}
                              style={{ display: "none" }}
                            />
                            <label
                              htmlFor="imageInput"
                              style={{ cursor: "pointer" }}
                              className="btn_fill_add_img_service"
                            >
                              Add Image
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sm-12 col-md-8 col-lg-8">
                    <div className="new_service_text_details_div">
                      <div className="select_new_srvic_heading">
                        <span
                          class="material-symbols-outlined"
                          style={{ color: "white" }}
                        >
                          concierge
                        </span>{" "}
                        &nbsp;
                        <h4>Select Service</h4>
                      </div>
                      <div className="btn_fill_service_parent_div">
                        {["Cosmetology"].map((category, index) => (
                          <button
                            key={index}
                            className={`btn btn_fill_service ${
                              selectedService === category
                                ? "selected_services_btun"
                                : ""
                            }`}
                            // className="btn btn_fill_service"
                            onClick={() => handleServiceChange(category)}
                          >
                            {category}
                          </button>
                        ))}
                      </div>

                      <div
                        style={{
                          backgroundColor: "white",
                          height: "2px",
                          margin: "10px 0px",
                        }}
                      ></div>
                      <div className="services_cancel_and_add_btn">
                        <div className="d-flex align-items-center">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="secondary"
                              id="dropdown-basic"
                              style={{ backgroundColor: "var(--pink)" }}
                            >
                              {selectedCategory}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {/* Use the subServices state to dynamically render dropdown options */}
                              {subServices.map((option, index) => (
                                <Dropdown.Item
                                  key={index}
                                  onClick={() => handleCategoryChange(option)}
                                >
                                  {option}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                      <div className="new_service_text_details_child_div">
                        <div className="new_service_name_price">
                          <input
                            type="text"
                            value={productName}
                            onChange={handleNameChange}
                            onBlur={handleNameBlur}
                            onClick={handleNameClick}
                            required
                            className="add_services_booking_input_field"
                            style={{ width: "100%" }}
                          />
                        </div>

                        <div
                          style={{
                            backgroundColor: "white",
                            height: "2px",
                            margin: "10px 0px",
                          }}
                        ></div>
                        <div className="new_service_name_price">
                          <button
                            className="btn btn_fill"
                            style={{ backgroundColor: "var(--pink)" }}
                            onClick={handleCancel}
                          >
                            Reset
                          </button>
                          <button
                            className="btn btn_fill"
                            onClick={handleAddProduct}
                            style={{ backgroundColor: "var(--pink)" }}
                          >
                            Add Service
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* show services from db */}
      <div>
        <h5
          style={{
            textAlign: "center",
            // marginTop: "100px",
            backgroundColor: "var(--click-color)",
            color: "white",
            // fontSize: "30px",
            padding: "5px",
            fontWeight: "700",
          }}
        >
          Cosmetology Services
        </h5>

        {/* Check if services exist */}
        {Object.keys(groupedServices).map((category) => (
          <div key={category} className="carousel_services_parent">
            <div className="services_heading carousel_services_heading">
              <p>{category}</p>
              <h1 id="top_heading"></h1>
            </div>
            <OwlCarousel {...dental_services_carousel_option}>
              {groupedServices[category].map((service) => (
                <div key={service.id}>
                  <div className="item carousel_img_parent">
                    <img
                      src={service.product_img_1}
                      alt={`Cosmetology Service ${service.id}`}
                      className="services_carousel_img"
                    />
                    <h2
                      className="services_img_heaing"
                      style={{ marginTop: "-10px" }}
                    >
                      <p
                        dangerouslySetInnerHTML={{
                          __html: service.sub_service.toString(),
                        }}
                      />
                    </h2>
                    {user && user.role === "admin" && (
                      <h2
                        className="services_img_status_heaing"
                        style={{
                          backgroundColor:
                            service.status === "active"
                              ? "var(--click-color)"
                              : "var(--pink)",
                          marginTop: "-10px",
                        }}
                      >
                        {service.status}
                      </h2>
                    )}
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        ))}
      </div>

      {/* top four services */}
      <div
        className="container parent_div_cosmetology"
        id="our-services-heading"
      >
        <div className="row">
          <div className="services_heading">
            <p>Discover Our Solutions</p>
            <h1 id="top_heading">
              Our Comprehensive Range of Tailored Services
            </h1>
          </div>

          <div className="col-md-3 col-6 img-parent">
            <img
              src="./assets/img/dental_img.jpg"
              alt="dental_img"
              className="services-img"
            />
            <h2>Hydrafacial</h2>
            <h5
              style={{
                textAlign: "justify",
              }}
            >
              This multi-step procedure combines deep cleansing, exfoliation,
              gentle extraction, intense hydration, and antioxidant infusion,
              all in one seamless process. Using a specialized device, it
              effectively clears away dead skin cells and impurities while
              deeply hydrating and nourishing the skin.
            </h5>
          </div>
          <div className="col-md-3 col-6 img-parent">
            <img
              src="./assets/img/dermatolgy-service.jpg"
              alt="dental_img"
              className="services-img"
            />
            <h2>Chemical Peel</h2>
            <h5
              style={{
                textAlign: "justify",
              }}
            >
              It is a popular skin resurfacing treatment that uses a chemical
              solution to exfoliate the skin, removing the outer layers of dead
              or damaged skin cells. This treatment stimulates new skin cell
              growth, brighter, and more even-toned skin.It’s a highly effective
              treatment to achieve youthful, refreshed skin.
            </h5>
          </div>
          <div className="col-md-3 col-6 img-parent">
            <img
              src="./assets/img/homeopathy.jpg"
              alt="dental_img"
              className="services-img"
            />
            <h2>Oxygeneo Facial</h2>
            <h5
              style={{
                textAlign: "justify",
              }}
            >
              It is an advanced, non-invasive skincare treatment that delivers
              three benefits in one session: exfoliation, oxygenation, and
              nourishment. This facial is designed to improve skin texture,
              reduce fine lines, and boost overall radiance, making it a popular
              choice for achieving a youthful, glowing complexion.
            </h5>
          </div>
          <div className="col-md-3 col-6 img-parent">
            <img
              src="./assets/img/hijama_img.jpg"
              alt="dental_img"
              className="services-img"
            />
            <h2>Laser Hair Removal</h2>
            <h5
              style={{
                textAlign: "justify",
              }}
            >
              It is a highly effective, non-invasive procedure that uses
              concentrated laser light to target and destroy hair follicles,
              preventing future hair growth. It's a long-lasting solution for
              unwanted hair, offering smoother skin with minimal discomfort. It
              offers a lasting solution to reduce or eliminate unwanted hair.
            </h5>
          </div>
        </div>
        <br />
        <div className="d-flex justify-content-center">
          <Link to="/contactus">
            <button className="btn_fill">Book an appointment</button>
          </Link>
        </div>
      </div>

      {/* youtube videos */}
      <div className="container">
        <div className="row youtube-videos-row-div">
          {youtubeVideos.map((media, index) => (
            <div key={index} className="col-lg-3 col-md-4 col-6 mb-4">
              {/* Adjust the column sizes based on screen size */}
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  title={`Social Video ${index + 1}`}
                  // width="100%"
                  // height="300"
                  src={getEmbedUrl(media)}
                  frameBorder="0"
                  allowFullScreen
                  className="youtube-media-style"
                ></iframe>
              </div>
            </div>
          ))}
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

export default PGCosmetology;
