import React, { useState, useEffect } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useLocation, Link } from "react-router-dom";
import { projectFirestore, timestamp } from "../../firebase/config";
import { useFirestore } from "../../hooks/useFirestore";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import Insurances from "../../components/Insurances";
import "../../components/ContactUs.css";
import "./PGDentalAds.scss";
import WhatsAppIcon from "../../components/WhatsAppIcon";
import CallUsIcon from "../../components/CallUsIcon";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

const PGDentalAds = () => {
  // const location = useLocation();
  // // Scroll to the top of the page whenever the location changes
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [location]);

  const navigate = useNavigate();

  const { user } = useAuthContext();
  const { addDocument } = useFirestore("enquiries");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    subject: "",
    message: "",
  });

  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);

  const phoneNumber = "+97142636717"; // Your phone number

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsPending(true);

    try {
      // Add contact data using addDocument hook
      await addDocument({
        ...formData,
        createdAt: timestamp.fromDate(new Date()),
      });

      setIsPending(false);
      setSuccessMessage(
        "We appreciate your message and will be in touch with you shortly. Thank you for reaching out to us!"
      );
      setFormData({
        name: "",
        email: "",
        phoneNumber: "",
        subject: "",
        message: "",
      });
      // navigate("/thank-you");
      // Redirect to thank-you page
      window.location.href = "/thank-you";
    } catch (err) {
      setError("Error sending data to Firebase");
      setIsPending(false);
      console.log("Error:", err);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDownloadEnquiries = async () => {
    try {
      // Fetch enquiries collection data from Firestore
      const enquiriesSnapshot = await projectFirestore
        .collection("enquiries")
        .get();

      // Create a CSV string
      let csvData = "Name,Email,Phone Number,Subject,Message,Timestamp\n"; // Header row

      // Iterate over each document in the collection
      enquiriesSnapshot.forEach((doc) => {
        const data = doc.data();
        // Append data to CSV string
        csvData += `${data.name},${data.email},${data.phoneNumber},${
          data.subject
        },${data.message},${data.createdAt.toDate()}\n`;
      });

      // Convert CSV string to Blob
      const blob = new Blob([csvData], { type: "text/csv" });

      // Create a temporary anchor element
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = "enquiries.csv";
      a.click();
    } catch (error) {
      console.error("Error downloading enquiries:", error);
    }
  };
  console.log("🚀 ~ handleDownloadEnquiries ~ handleDownloadEnquiries:", handleDownloadEnquiries)
  console.log("🚀 ~ handleDownloadEnquiries ~ handleDownloadEnquiries:", handleDownloadEnquiries)
  console.log("🚀 ~ handleDownloadEnquiries ~ handleDownloadEnquiries:", handleDownloadEnquiries)
  console.log("🚀 ~ handleDownloadEnquiries ~ handleDownloadEnquiries:", handleDownloadEnquiries)
  console.log("🚀 ~ handleDownloadEnquiries ~ handleDownloadEnquiries:", handleDownloadEnquiries)
  console.log("🚀 ~ handleDownloadEnquiries ~ handleDownloadEnquiries:", handleDownloadEnquiries)
  console.log("🚀 ~ handleDownloadEnquiries ~ handleDownloadEnquiries:", handleDownloadEnquiries)

  // videos arrey
  const youtubeVideos = [
    "https://youtube.com/shorts/5pLUrxr7TL0?si=WyqzKvhY1vDzAYJ_",
    "https://youtube.com/shorts/hIIFE2Q8bFQ?si=7W4YkdzM5ATGvkke",
    "https://youtube.com/shorts/gOcnqhhzK-4?si=ORIYuxEaMhP-Hh2_",
    "https://youtube.com/shorts/tRyNEZbZ3t4?si=JFwLEwqz57BTzZHJ",
    "https://youtube.com/shorts/2LBpjkmlNSE?si=GsQgCLEh-TnohowN",
    "https://youtube.com/shorts/MfDSwr0kCdw?si=JkS-RB6JD3bUL3Dq",
    "https://youtube.com/shorts/rWa0uPABoRk?feature=shared",
    "https://youtube.com/shorts/l6baoMyoOQA?si=iuLWog7I7gSAc6vX",
    "https://youtube.com/shorts/qUMBkVsIs94?feature=shared",
    "https://youtube.com/shorts/nqYmjcDum_c?feature=shared",
  ];

  // dental images array
  const dentalImages = [
    {
      id: 1,
      name: "Aesthetic Dentistry",
      imgSrc: "assets/img/dental-img-1.jpeg",
    },
    {
      id: 2,
      name: "Zirconia Crown",
      imgSrc: "assets/img/dental-img-2.jpeg",
    },
    {
      id: 3,
      name: "Flexible Removable Partial Dentures",
      imgSrc: "assets/img/dental-img-3.jpeg",
    },
    {
      id: 4,
      name: "Invisalign Braces",
      imgSrc: "assets/img/dental-img-4.jpeg",
    },
    {
      id: 5,
      name: "Flexible Dentures",
      imgSrc: "assets/img/dental-img-5.jpeg",
    },
    {
      id: 6,
      name: "Veneers",
      imgSrc: "assets/img/dental-img-6.jpeg",
    },
    {
      id: 7,
      name: "Happy Smiles",
      imgSrc: "assets/img/dental-img-7.jpeg",
    },
    {
      id: 8,
      name: "Tooth Gems",
      imgSrc: "assets/img/dental-img-8.jpeg",
    },
    {
      id: 9,
      name: "Lumineers",
      imgSrc: "assets/img/dental-img-9.jpeg",
    },
    {
      id: 10,
      name: "Teeth Whitening",
      imgSrc: "assets/img/dental-img-10.jpeg",
    },
    {
      id: 11,
      name: "Partial Denture",
      imgSrc: "assets/img/dental-img-11.jpeg",
    },
    {
      id: 12,
      name: "Hollywood Smile",
      imgSrc: "assets/img/dental-img-12.jpeg",
    },
  ];

  const getEmbedUrl = (youtubeVideos) => {
    if (typeof youtubeVideos === "string") {
      // Check for YouTube shorts URL
      const shortsMatch = youtubeVideos.match(
        /youtube\.com\/shorts\/([\w-]+)\??/
      );
      if (shortsMatch) {
        const mediaId = shortsMatch[1];
        return `https://www.youtube.com/embed/${mediaId}`;
      }

      // Check for regular YouTube media URL
      const regularMatch = youtubeVideos.match(/youtu\.be\/([\w-]+)/);
      if (regularMatch) {
        const mediaId = regularMatch[1];
        return `https://www.youtube.com/embed/${mediaId}`;
      }
    }
    // Add other social platforms as needed
    // Example for Vimeo: if (youtubeVideos.includes("vimeo.com/")) return "vimeo embed URL";
    // Default case
    return youtubeVideos;
  };

  // Create a ref for the Contact Us section
  const contactUsRef = useRef(null);

  // Scroll to the Contact Us section when the button is clicked
  const handleBookAppointmentClick = () => {
    if (contactUsRef.current) {
      contactUsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <WhatsAppIcon />
      <CallUsIcon />
      <div className="derma-ads-banner-div">
        <div className="pg_contact_us">
          <div className="banner relative">
            <img src="./assets/img/Untitled design (4).png" alt="Banner 32" />
            <div className="banner_content">
              <div className="bc_inner">
                <div className="container">
                  <h3 className="banner_subheading">BOOK AN APPOINTMENT</h3>
                  <h2 className="banner_heading">Dentamax Dental Clinic</h2>
                  <button
                    className="btn_fill"
                    onClick={handleBookAppointmentClick}
                  >
                    Book an Appointment
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="container">
        <div className="row ads-category-card">
          <div className="col-12 d-flex justify-content-center flex-column">
            <div className="top-content d-flex justify-content-between align-items-start">
              <div className="image-side">
                <img
                  src="/assets/img/22-logo.webp"
                  className="award-ribbon-number"
                />
                <br />
                <button
                  className="btn_fill"
                  onClick={handleBookAppointmentClick}
                >
                  Book an Appointment
                </button>
              </div>
              <div className="text-side">
                <h1 className="heading_below-underline"></h1>
                <br />
                <h1 className="heading_below-underline">
                  <span>
                    Welcome to Dentamax Dental Clinic, Dubai's Premier Dental
                    Destination
                  </span>
                </h1>
                <br />
                <p style={{ fontSize: "20px" }}>
                  At Dentamax Clinic, we are committed to helping you achieve a
                  confident and radiant smile. As the top dental clinic in
                  Dubai, we specialize in providing a comprehensive range of
                  dental services, including:
                </p>
                <br />
                <h5>
                  <b>General Dentistry:</b> Our skilled dentists offer routine
                  check-ups, cleanings, and preventive care to maintain your
                  oral health.
                  <br />
                  <br />
                  <b>Cosmetic Dentistry:</b> Enhance your smile with our
                  advanced cosmetic procedures, including teeth whitening,
                  veneers, and smile makeovers.
                  <br />
                  <br />
                  <b>Orthodontics:</b> Straighten your teeth and improve your
                  bite with our state-of-the-art orthodontic treatments,
                  including braces and clear aligners.
                </h5>
              </div>
            </div>
            <div className="full-width-text">
              <h5 style={{ margin: "30px 0px 0px 0px" }}>
                <b>Dental Implants:</b> Restore missing teeth with our durable
                and natural-looking dental implants, providing a permanent
                solution for tooth loss.
                <br />
                <br />
                <b>Pediatric Dentistry:</b> We provide gentle and compassionate
                dental care for children, ensuring a positive experience and
                healthy smiles for the youngest members of your family.
                <br />
                <br />
                <b>Oral Surgery:</b> Our experienced oral surgeons are equipped
                to handle complex dental procedures, including wisdom teeth
                extractions and corrective jaw surgery.
                <br />
                <br />
                Book your appointment today and discover why Dentamax Clinic is
                the trusted choice for dental care in Dubai. Let us help you
                smile with confidence!
                <br />
                <br />
                <b>Why Choose Us?</b> <br />
                <br />
                Recognized as
                <span style={{ color: "var(--pink)" }}>
                  <b> Dubai's #1 Dermatology Clinic.</b>
                </span>
                <br />
                <br />
                <b>Personalized Approach:</b> Each treatment plan is
                personalized to meet your specific needs.
                <br />
                <br />
                <b>Commitment to Excellence:</b> Ensuring you receive the
                highest quality of care and results. Experience the difference
                at Dentamax Dermatology Clinic. Contact us today to schedule
                your consultation and take the first step towards radiant,
                healthy skin.
              </h5>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div
        className="d-flex justify-content-center"
        id="redirect-to-contactus-section"
      >
        <button className="btn_fill" onClick={handleBookAppointmentClick}>
          Book an Appointment
        </button>
      </div>
      <br />
      <br />
      <div className="container">
        <div className="row">
          {dentalImages &&
            dentalImages.map((image, index) => (
              <div className="col-6 col-md-4 col-lg-3" key={index}>
                <img
                  className="dental_imgs_ads"
                  src={image.imgSrc}
                  alt={`dental image ${image.id}`}
                />
                <div className="d-flex justify-content-center mt-2">
                  <h5>{image.name}</h5>
                </div>
              </div>
            ))}
        </div>
      </div>
      <br />
      <br />
      <section
        className="dental_services"
        id="services-section"
        style={{ padding: "20px 0px" }}
      >
        <div className="container">
          <div className="section-title">
            <p>Our Expertise</p>
            <h2>Dental Treatments</h2>
          </div>
          <div className="ds_inner">
            <div className="ds_single dsb_r dsb_b dsmb_b dsmb_r">
              <div className="dss_inner">
                <div className="service_icon">
                  <img
                    src="./assets/img/dental_filling.png"
                    alt="dental filling img"
                  />
                </div>
                <h1 className="dental_service_name">
                  Dental <br />
                  filling
                </h1>
              </div>
            </div>
            <div className="ds_single dsb_r dsb_b dsmb_b dsmb_r">
              <div className="dss_inner">
                <div className="service_icon">
                  <img
                    src="./assets/img/straightens-teeth.png"
                    alt="steaghtens img"
                  />
                </div>
                <h1 className="dental_service_name">bridges </h1>
              </div>
            </div>
            <div className="ds_single dsb_r dsb_b dsmb_b">
              <div className="dss_inner">
                <div className="service_icon">
                  <img
                    src="./assets/img/root_canal_treatment.png"
                    alt="root canel img"
                  />
                </div>
                <h1 className="dental_service_name">
                  endodontics
                  <br />
                  treatment
                </h1>
              </div>
            </div>
            <div className="ds_single dsb_r dsb_b dsmb_b dsmb_r">
              <div className="dss_inner">
                <div className="service_icon">
                  <img src="./assets/img/denture.png" alt="dentare img" />
                </div>
                <h1 className="dental_service_name">
                  primary tooth extracation
                </h1>
              </div>
            </div>
            <div className="ds_single dsb_r dsb_b dsmb_b dsmb_r">
              <div className="dss_inner">
                <div className="service_icon">
                  <img
                    src="./assets/img/dental-implant.png"
                    alt="dental implant img"
                  />
                </div>
                <h1 className="dental_service_name">
                  fiber <br />
                  post
                </h1>
              </div>
            </div>

            <div className="ds_single dsb_b dsmb_b">
              <div className="dss_inner">
                <div className="service_icon">
                  <img src="./assets/img/teethpair.png" alt=" teethpair img" />
                </div>
                <h1 className="dental_service_name">
                  crown <br /> re-cementation
                </h1>
              </div>
            </div>
            <div className="ds_single dsb_r dsmb_b dsmb_r">
              <div className="dss_inner">
                <div className="service_icon">
                  <img src="./assets/img/children.png" alt="children img" />
                </div>
                <h1 className="dental_service_name">
                  rct <br /> anterior
                </h1>
              </div>
            </div>
            <div className="ds_single dsb_r dsmb_b dsmb_r">
              <div className="dss_inner">
                <div className="service_icon">
                  <img src="./assets/img/kidsteeth.png" alt="kids theeth img" />
                </div>
                <h1 className="dental_service_name">
                  post <br /> & care
                </h1>
              </div>
            </div>

            <div className="ds_single dsb_r dsmb_b">
              <div className="dss_inner">
                <div className="service_icon">
                  <img
                    src="./assets/img/tooth-whitening.png"
                    alt="tooth whitening img"
                  />
                </div>
                <h1 className="dental_service_name">
                  teeth <br /> cleaning
                </h1>
              </div>
            </div>
            <div className="ds_single dsb_r dsmb_r">
              <div className="dss_inner">
                <div className="service_icon">
                  <img
                    src="./assets/img/smilemakeover.png"
                    alt="smile makeover img"
                  />
                </div>
                <h1 className="dental_service_name">
                  zoom <br /> whitening
                </h1>
              </div>
            </div>
            <div className="ds_single dsb_r dsmb_r">
              <div className="dss_inner">
                <div className="service_icon">
                  <img src="./assets/img/mouth.png" alt="mouth img" />
                </div>
                <h1 className="dental_service_name">
                  night <br /> guard
                </h1>
              </div>
            </div>
            <div className="ds_single dsb_r dsmb_r">
              <div className="dss_inner">
                <div className="service_icon">
                  <img src="./assets/img/counter_6.png" alt="mouth img" />
                </div>
                <h1 className="dental_service_name">
                  Laser <br /> dentistry
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="d-flex justify-content-center">
        <a href={`tel:${phoneNumber}`}>
          <button className="btn_fill">Book a Consultation Call</button>
        </a>
      </div>
      <br />
      <br />
      <div className="container">
        <div className="section-title">
          <p>Our Dental Experts</p>
          <h2>Find top dental specialist at Dentamax Medical Center</h2>
        </div>
        <div className="row ads-category-card">
          <div className="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column img-first">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/dentamax-dev.appspot.com/o/doctor_img%2FWhatsApp%20Image%202024-06-23%20at%2010.56.41%20PM.jpeg?alt=media&token=515db105-e209-4ccb-8812-1242a463cd74"
              alt="Doctor img"
              className="award-ribbon-number"
              style={{ borderRadius: "20px" }}
            />
            <br />
            <h5>Dr. Shreekala Thazhe Veedu</h5>
            <h6 style={{ color: "var(--pink)", fontWeight: "800" }}>
              General Dentist
            </h6>
            <br />
            <button className="btn_fill" onClick={handleBookAppointmentClick}>
              Book an Appointment
            </button>
          </div>
          <div className="col-12 col-md-6 d-flex align-items-center justify-content-center text-first">
            <h5 style={{ margin: "30px 0px 30px 0px" }}>
              At Dentamax Clinic, <b>Dr. Shreekala Thazhe Veedu</b> brings
              extensive experience and a compassionate approach to dental care
              <br />
              <br />
              <b>Specializations :</b>
              <br />
              1. Ceramic and Composite Veneers,
              <br /> 2. Composite fillings,
              <br />
              3. Crowns,
              <br /> 4. Teeth whitening
              <br /> 5. Children's treatments
              <br />
              6. Fillings,
              <br />
              7. Pulpectomy <br />
              8. Extractions. <br />
              <br />
              Dr. Shreekala is dedicated to personalized care, helping you
              achieve a healthier, more confident smile.
              <br />
              <br />
              <button className="btn_fill" onClick={handleBookAppointmentClick}>
                Book an Appointment
              </button>
            </h5>
          </div>
        </div>
        <div className="row ads-category-card  ">
          <div className="col-12 col-md-6 d-flex align-items-center justify-content-center text-first">
            <h5 style={{ margin: "30px 0px 30px 0px" }}>
              <b>Dr. Nithya Sheela</b> is dedicated to delivering exceptional
              dental care with a focus on:
              <br />
              <br />
              <b>Specializations :</b>
              <br />
              1. State-of-the-art technology
              <br />
              2. Patient-centered approach
              <br />
              3. Specialized in preventive and cosmetic dentistry
              <br /> 4. Expert Dental Care
              <br />
              <br />
              As a general dentist, Dr. Nithya ensures the highest quality of
              care for a healthier, more radiant smile. Trust Dr. Nithya Sheela
              to prioritize your optimal oral health with expertise and
              compassion.
              <br />
              <br />
              <button className="btn_fill" onClick={handleBookAppointmentClick}>
                Book an Appointment
              </button>
            </h5>
          </div>
          <div className="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column img-first">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/dentamax-dev.appspot.com/o/doctor_img%2FWhatsApp%20Image%202024-06-23%20at%2010.56.39%20PM.jpeg?alt=media&token=467f9f98-764c-488c-b230-b473e0593fb7"
              alt="Doctor img"
              className="award-ribbon-number"
              style={{ borderRadius: "20px" }}
            />
            <br />
            <h5>Dr. Nithya Sheela</h5>
            <h6 style={{ color: "var(--pink)", fontWeight: "800" }}>
              General Dentist
            </h6>
            <br />
            <button className="btn_fill" onClick={handleBookAppointmentClick}>
              Book an Appointment
            </button>
          </div>
        </div>
        <div className="row ads-category-card">
          <div className="col-12 col-md-6 d-flex justify-content-center align-items-center flex-column img-first">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/dentamax-prod.appspot.com/o/doctor_img%2Flincy.png?alt=media&token=8e86fb4e-8ceb-4acf-8c50-a640b6727627"
              alt="Doctor img"
              className="award-ribbon-number"
              style={{ borderRadius: "20px" }}
            />
            <br />
            <h5>Dr. Lincy Stephen Joseph</h5>
            <h6 style={{ color: "var(--pink)", fontWeight: "800" }}>
              Orthodontist
            </h6>
            <br />
            <button className="btn_fill" onClick={handleBookAppointmentClick}>
              Book an Appointment
            </button>
          </div>
          <div className="col-12 col-md-6 d-flex align-items-center justify-content-center text-first">
            <h5 style={{ margin: "30px 0px 30px 0px" }}>
              <b>Dr. Lincy Stephen Joseph</b> is a highly skilled orthodontist
              dedicated to providing top-notch dental care with a focus on
              advanced techniques and personalized Specialisations
              <br />
              <br />
              <b>Specializations :</b>
              <br />
              1. Dentofacial Orthopedics
              <br />
              2. Specialized In orthodontics
              <br />
              3. Invisalign Certified
              <br /> 4. Certified in Lasers & Its Application In Dentistry
              <br />
              <br />
              With her commitment to using state-of-the-art methods and a
              patient-centered approach, Dr. Lincy ensures exceptional care for
              achieving a healthier, more beautiful smile.
              <br />
              <br />
              <button className="btn_fill" onClick={handleBookAppointmentClick}>
                Book an Appointment
              </button>
            </h5>
          </div>
        </div>
      </div>
      <br />
      {/* START BEFORE AFTER DENTAL */}
      <div className="image_slider_parents_div">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 d-flex align-items-center">
              <div className="beforeaftrer-left-div">
                <h4 style={{ color: "var(--black-color)" }}>Smile Gallery</h4>
                <h1 style={{ color: "var(--black-color)" }}>
                  A Beautiful Smile is Just a Visit Away
                </h1>
                <p style={{ color: "var(--black-color)" }}>
                  Our smile gallery showcases the amazing transformations we’ve
                  accomplished for our patients, highlighting the power of a
                  beautiful smile. From whitening to orthodontics, we offer a
                  range of tailored solutions to help you achieve your dream
                  smile. Explore our gallery and discover how we can help you
                  transform your smile today!
                </p>
                <button
                  className="btn_fill"
                  onClick={handleBookAppointmentClick}
                >
                  Book an Appointment
                </button>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 d-flex justify-content-center">
              <div
                className="smile-gallery-parent"
                style={{ position: "relative" }}
              >
                <ReactCompareSlider
                  style={{
                    height: "500px",
                    // width: "400px",
                    borderRadius: "10px",
                    border: "3px solid pink",
                  }}
                  itemOne={
                    <>
                      <ReactCompareSliderImage
                        src="/assets/img/smile-before.jpeg"
                        srcSet="/assets/img/smile-before.jpeg"
                        alt="Image one"
                      />
                      <div
                        className="smile-label"
                        style={{
                          position: "absolute",
                          bottom: 10,
                          left: 10,
                          backgroundColor: "grey",
                          padding: "5px 10px",
                          borderRadius: "20px",
                          fontWeight: "700",
                          color: "white",
                        }}
                      >
                        <h5>Before</h5>
                      </div>
                    </>
                  }
                  itemTwo={
                    <>
                      <ReactCompareSliderImage
                        src="/assets/img/smile-after.jpeg"
                        srcSet="/assets/img/smile-after.jpeg"
                        alt="Image two"
                      />
                      <div
                        className="smile-label"
                        style={{
                          position: "absolute",
                          bottom: 10,
                          right: 10,
                          backgroundColor: "grey",
                          padding: "5px 10px",
                          borderRadius: "20px",
                          fontWeight: "700",
                          color: "white",
                        }}
                      >
                        <h5>After</h5>
                      </div>
                    </>
                  }
                />
              </div>
            </div>

            <div className="beforeafter_dta_right">
              <p>
                Our smile gallery showcases the amazing transformations we’ve
                accomplished for our patients, highlighting the power of a
                beautiful smile. From whitening to orthodontics, we offer a
                range of tailored solutions to help you achieve your dream
                smile. Explore our gallery and discover how we can help you
                transform your smile today!
              </p>
              <button className="btn_fill" onClick={handleBookAppointmentClick}>
                Book an Appointment
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* END BEFORE AFTER BEAUTY */}
      {/* YOUTUBE VIDEOS */}
      <br />
      <br />
      <div className="container">
        <div className="row">
          {youtubeVideos.map((videolink, index) => (
            <div className="col-12 col-md-4">
              <iframe
                title={`Social Video ${index + 1}`}
                src={getEmbedUrl(videolink)}
                frameBorder="0"
                allowFullScreen
                className="youtube-media-style"
              ></iframe>
            </div>
          ))}
        </div>
      </div>
      {/* contact us section start */}
      <br /> <br />
      <div className="container">
        <div className="section-title">
          <p>Dentamax Medical Center, Dubai</p>
          <h2>Click here for the location</h2>
        </div>
      </div>
      <section
        className="form_sec"
        id="redirected-to-contactus-section"
        style={{ marginTop: "20px" }}
      >
        <div className="contact_us_left_img">
          {/* <img src="./assets/img/22-logo.webp" alt="22 year logo img" /> */}
          <iframe
            title="Dentamax Clinic Location"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3608.098239761867!2d55.3774914!3d25.2672805!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5d35b0d8fc8b%3A0x181c5db217cac9ee!2sDentamax%20Medical%20Center%2C%20Dubai!5e0!3m2!1sen!2sin!4v1695569822534!5m2!1sen!2sin"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen
          ></iframe>
        </div>
        <div
          className="right_form"
          style={{
            backgroundImage: "url('./assets/img/contactformbg.webp')",
          }}
          ref={contactUsRef}
        >
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="d-flex justify-content-center">
                <h3
                  style={{
                    color: "white",
                    fontWeight: "700",
                    marginBottom: "30px",
                  }}
                >
                  Contact Us
                </h3>
              </div>
              <div className="col-sm-6">
                <div className="form_field">
                  <input
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                  <div className="field_icon">
                    <span className="material-symbols-outlined">Person</span>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form_field">
                  <input
                    type="email"
                    placeholder="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                  <div className="field_icon">
                    <span className="material-symbols-outlined">mail</span>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form_field">
                  <input
                    type="tel"
                    placeholder="Phone Number"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                    required
                  />
                  <div className="field_icon">
                    <span className="material-symbols-outlined">call</span>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form_field">
                  <input
                    type="text"
                    placeholder="Subject"
                    name="subject"
                    value={formData.subject}
                    onChange={handleInputChange}
                    required
                  />
                  <div className="field_icon">
                    <span className="material-symbols-outlined">subject</span>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form_field">
                  <textarea
                    type="text"
                    placeholder="Message"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    required
                  />
                  <div className="field_icon">
                    <span className="material-symbols-outlined">chat</span>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="submit_btn">
                  <button
                    type="submit"
                    className="btn_fill"
                    disabled={isPending}
                  >
                    {isPending ? "Sending..." : "Send"}
                  </button>
                </div>
                {error && <p className="error">{error}</p>}
                {successMessage && <p className="success">{successMessage}</p>}
              </div>
              {user && user.role == "admin" && (
                <button className="btn_fill" onClick={handleDownloadEnquiries}>
                  Download Enquiries
                </button>
              )}
            </div>
          </form>
        </div>
      </section>
      {/* contact us section end */}
      <div className="container">
        <div className="section-title">
          <p>Our Insurances Partners</p>
          <h2>Comprehensive protection with our insurance plans</h2>
        </div>
      </div>
      <Insurances />
    </div>
  );
};

export default PGDentalAds;
